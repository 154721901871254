export default [
  {
    title: "From Date",
    minWidth: 80,
    key: 'fromdate',
    sortable: true,
  },
  {
    title: "Leave Type",
    key: "typeofleave",
    sortable: true,
    minWidth: 80,
  },
  {
    title: "CR",
    key: "cr",
    minWidth: 80,
  },
  {
    title: "DR",
    key: "dr",
    minWidth: 80,
  },
  {
    title: "Balance Leave",
    key: "balance",
    minWidth: 80,
  },
  {
    title: "Notes",
    key: "notes",
    minWidth: 80,
  },
];
